import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandFonts,
  fontWeights,
  fluidFontSize,
} from '../styles';
import { Container, Button } from './ui';

const StyledSubBanner = styled.section``;

const StyledContainer = styled(Container)`
  ${maxBreakpointQuery.mlarge`
    padding-right: 0;
    padding-left: 0;
  `}
`;

const StyledInner = styled.div`
  position: relative;
  background-color: ${standardColours.white};
`;

const StyledImage = styled(GatsbyImage)`
  opacity: 0.9;

  ${maxBreakpointQuery.medium`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;

const StyledContent = styled.div`
  position: relative;
  margin: auto;
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 660px;
  text-align: center;

  ${minBreakpointQuery.medium`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
`;

const StyledHeading = styled.h1`
  color: ${standardColours.black};
  font-family: ${({ isProductCategoryPage }) =>
    isProductCategoryPage ? brandFonts.tertiary : brandFonts.secondary};
  font-weight: ${fontWeights.regular};
  ${fluidFontSize(
    '35px',
    '70px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
`;

const StyledButton = styled(Button)`
  margin-top: 30px;

  ${minBreakpointQuery.small`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 50px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 60px;
  `}
`;

const SubBanner = ({ heading, image, link, isProductCategoryPage }) => (
  <StyledSubBanner>
    <StyledContainer>
      <StyledInner>
        <StyledImage
          image={image.gatsbyImageData}
          alt={image.alt}
          loading="eager"
          fetchPriority="high"
        />
        <StyledContent>
          <StyledHeading isProductCategoryPage={isProductCategoryPage}>
            {heading}
          </StyledHeading>
          {link && <StyledButton to={link.page}>{link.text}</StyledButton>}
        </StyledContent>
      </StyledInner>
    </StyledContainer>
  </StyledSubBanner>
);

export default SubBanner;

export const SubBannerImageFragment = graphql`
  fragment SubBannerImageFragment on DatoCmsFileField {
    gatsbyImageData(width: 1440, height: 320)
    alt
  }
`;
