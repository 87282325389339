import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import SubBanner from '../components/SubBanner';
import ModularBlocks from '../components/ModularBlocks';

const NotFoundPage = () => {
  const {
    datoCmsNotFound: { title, bannerImage, modularBlocks },
  } = useStaticQuery(graphql`
    query NotFoundPageQuery {
      datoCmsNotFound {
        title
        bannerImage {
          ...SubBannerImageFragment
        }
        modularBlocks {
          ...ContentModularBlockFragment
        }
      }
    }
  `);

  return (
    <Layout noIndex={true}>
      <main>
        <SubBanner heading={title} image={bannerImage} />
        <ModularBlocks items={modularBlocks} />
      </main>
    </Layout>
  );
};

export default NotFoundPage;
